import { useCallback, useMemo } from 'react'
import { createThreadRunStreamReader } from '@cleanlab/design-system/chat'
import type { ThreadStreamChunk } from '@cleanlab/design-system/chat'
import {
  useBareMessagesStore,
  useMessagesStore
} from '@/providers/messages-store-provider'
import { useRagAppStore } from '@/providers/rag-app-store-provider'
import type { StoreMessage, ThreadError } from '@/stores/messages-store'
import { trpc } from '@/trpc/client'
import type { SetOptional } from 'type-fest'
import { getChatPath } from '@/lib/consts'
import { assertExhaustive } from '@/lib/ts/assertExhaustive'
import { nanoid } from '@/lib/utils'

export const CurrentThreadStatus = {
  threadPending: 'threadPending',
  responsePending: 'responsePending',
  contentPending: 'contentPending',
  metadataPending: 'metadataPending',
  complete: 'complete',
  failed: 'failed'
} as const satisfies Record<string, string>

export type CurrentThreadStatus =
  (typeof CurrentThreadStatus)[keyof typeof CurrentThreadStatus]

const RetryLabels = { sendAgain: 'Send message again', retry: 'Retry' } as const

const getErrorFromCurrentStatus = (status: CurrentThreadStatus | undefined) => {
  const error: ThreadError | undefined = (() => {
    switch (status) {
      case undefined:
      case CurrentThreadStatus.threadPending:
        return {
          message: 'Could not create thread',
          canRetry: true
        }
      case CurrentThreadStatus.responsePending:
        return {
          message: 'Unable to send message',
          canRetry: true
        }
      case CurrentThreadStatus.contentPending:
        return {
          message: 'Response did not complete',
          canRetry: true,
          retryLabel: RetryLabels.sendAgain
        }
      case CurrentThreadStatus.metadataPending:
        return {
          message: 'Could not retrieve trustworthiness score',
          canRetry: true,
          retryLabel: RetryLabels.sendAgain
        }
      case CurrentThreadStatus.complete:
      case CurrentThreadStatus.failed:
        return undefined
      default:
        assertExhaustive(status)
    }
  })()
  if (error) {
    error.atStatus = status
  }
  return error
}

const createInitialMessages = ({
  content,
  threadId
}: {
  content: string
  threadId: string
}) => {
  const dateString = new Date().toISOString()

  return [
    {
      localId: nanoid(),
      role: 'user',
      content: content,
      thread_id: threadId,
      metadata: {},
      created_at: dateString,
      updated_at: dateString,
      isPending: true
    },
    {
      localId: nanoid(),
      role: 'assistant',
      content: '',
      thread_id: threadId,
      metadata: {},
      created_at: dateString,
      updated_at: dateString,
      isPending: true,
      isContentPending: true
    }
  ] as const satisfies StoreMessage[]
}

function useStreamMessage({
  assistantId,
  assistantSlug
}: {
  assistantId: string | null | undefined
  assistantSlug: string | null | undefined
}) {
  const currentThread = useMessagesStore(state => state.currentThread)
  const setCurrentThread = useMessagesStore(state => state.setCurrentThread)
  const appendMessage = useMessagesStore(state => state.appendMessage)
  const updateMessageContent = useMessagesStore(
    state => state.updateMessageContent
  )
  const updateMessage = useMessagesStore(state => state.updateMessage)
  const updateMessageMetadata = useMessagesStore(
    state => state.updateMessageMetadata
  )
  const messageIsPending = useMessagesStore(
    state => state.currentThread?.isPending
  )
  const setThreadStatus = useMessagesStore(state => state.setThreadStatus)

  const addHistoryThread = useRagAppStore(state => state.addHistoryThread)

  const setDone = useCallback(
    (opts: SetOptional<Parameters<typeof setThreadStatus>[0], 'status'>) => {
      setThreadStatus({
        status: opts.error
          ? CurrentThreadStatus.failed
          : CurrentThreadStatus.complete,
        ...opts
      })
    },
    [setThreadStatus]
  )

  const bareStore = useBareMessagesStore()

  const handleChunk = useCallback(
    ({ threadId, value }: { threadId: string; value: ThreadStreamChunk }) => {
      if (value.object === 'thread.message.created') {
        appendMessage({
          threadId: value.data.thread_id,
          message: {
            ...value.data,
            isPending: value?.data?.role === 'assistant',
            isContentPending: value?.data?.role === 'assistant'
          }
        })
        setThreadStatus({
          threadId,
          status: CurrentThreadStatus.responsePending
        })
      } else if (value.object === 'thread.message.metadata.delta') {
        updateMessageMetadata({
          threadId: threadId,
          messageId: value.id,
          metadata: value.delta.metadata
        })
        setThreadStatus({
          threadId,
          status: CurrentThreadStatus.complete
        })
      } else if (value.object === 'thread.message.content.delta') {
        const textValue = value?.delta?.content?.[0]?.text_value
        if (textValue) {
          updateMessageContent({
            threadId: threadId,
            messageId: value.id,
            newContent: textValue
          })
        }
        setThreadStatus({
          threadId,
          status: CurrentThreadStatus.contentPending
        })
      } else if (value.object === 'thread.message.content.completed') {
        console.info('thread.message.content.completed', value.data)
        updateMessage({
          threadId: threadId,
          messageId: value.id,
          data: {
            isContentPending: false
          }
        })
        setThreadStatus({
          threadId,
          status: CurrentThreadStatus.metadataPending
        })
      } else if (value.object === 'thread.run.completed') {
        setDone({ threadId })
      } else if (value.object === 'thread.run.failed') {
        setDone({
          threadId,
          error: {
            message: 'Unknown failure',
            canRetry: true,
            atStatus: bareStore.getState().currentThread?.status
          }
        })
        console.error('thread.run.failed', value.data)
      } else {
        console.info('Unhandled streaming event:', value.object)
      }
    },
    [
      appendMessage,
      bareStore,
      setDone,
      setThreadStatus,
      updateMessage,
      updateMessageContent,
      updateMessageMetadata
    ]
  )

  const postMessage = useCallback(
    async ({
      threadId,
      localThreadId,
      messageContent
    }: {
      threadId: string
      localThreadId?: string
      messageContent?: string
    }) => {
      let response: Response | undefined

      if (!threadId) {
        console.error('Invalid threadId', {
          threadId,
          messageContent
        })
        return
      }
      setThreadStatus({
        threadId,
        localThreadId,
        status: CurrentThreadStatus.responsePending,
        error: undefined
      })

      try {
        response = await fetch(`/api/agility/threads/${threadId}/runs/stream`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          body: JSON.stringify({
            model: 'gpt-4o',
            assistant_id: assistantId,
            thread_id: threadId,
            ...(messageContent
              ? {
                  additional_messages: [
                    {
                      role: 'user',
                      content: messageContent,
                      metadata: {},
                      thread_id: threadId
                    }
                  ]
                }
              : {})
          })
        })
      } catch (e) {
        setDone({
          threadId,
          error: {
            message: 'Something went wrong sending your message.',
            canRetry: true
          },
          status: CurrentThreadStatus.failed
        })
        return
      }

      if (!response.body) {
        setDone({
          threadId,
          error: {
            message: 'This browser does not support streaming responses.',
            canRetry: false
          },
          status: CurrentThreadStatus.failed
        })
        return
      }

      const reader = createThreadRunStreamReader(response.body)

      const errHandler = (err: Error) => {
        console.error('Error in stream reader', err)
        setDone({
          threadId,
          error: getErrorFromCurrentStatus(
            bareStore.getState().currentThread?.status
          )
        })
      }
      reader
        .read()
        .then(function pump({ done, value }) {
          if (done) {
            const currentThread = bareStore.getState().currentThread
            if (currentThread?.status !== 'complete') {
              const error =
                currentThread?.error ??
                getErrorFromCurrentStatus(currentThread?.status)
              setDone({
                threadId,
                localThreadId,
                status: CurrentThreadStatus.failed,
                error: error
              })
            }
            return
          }
          handleChunk({ threadId, value })
          reader.read().then(pump).catch(errHandler)
        })
        .catch(errHandler)
    },
    [assistantId, bareStore, handleChunk, setDone, setThreadStatus]
  )

  const { mutate: createThread, isPending: createThreadPending } =
    trpc.newThread.useMutation({
      onError: (error, variables) => {
        console.error('Error creating thread:', error)
        setThreadStatus({
          localThreadId: variables.localId,
          status: CurrentThreadStatus.failed,
          error: { message: 'Could not create thread', canRetry: true }
        })
      },
      onSuccess: (data, variables) => {
        if ('error' in data) {
          console.error('Error creating thread:', data.error)
          setThreadStatus({
            localThreadId: variables.localId,
            status: CurrentThreadStatus.failed,
            error: { message: 'Could not create thread', canRetry: true }
          })
          return
        }
        if (!assistantId) {
          console.error('Missing assistantId')
          return
        }
        addHistoryThread({
          thread: data.thread,
          localThreadId: data.localThreadId,
          assistantId,
          title: variables.messages?.[0]?.content || 'New thread'
        })
        setCurrentThread({
          threadId: data.thread?.id,
          messages: variables.messages || [],
          isPending: true,
          status: CurrentThreadStatus.contentPending
        })
        {
          assistantSlug &&
            window?.history.pushState(
              {},
              '',
              getChatPath(assistantSlug, data.thread.id)
            )
        }
        postMessage({
          threadId: data.thread.id,
          localThreadId: variables.localId,
          messageContent: variables.messages?.[0]?.content
        })
      }
    })

  const isPending = messageIsPending || createThreadPending
  const createThreadAndPostMessage = useCallback(
    async ({ messageContent }: { messageContent: string }) => {
      if (!assistantId) return

      const localThreadId = nanoid()
      addHistoryThread({
        localThreadId,
        assistantId,
        title: messageContent || 'New thread'
      })
      const initialMessages = createInitialMessages({
        threadId: localThreadId,
        content: messageContent
      })
      setCurrentThread({
        localThreadId,
        messages: initialMessages,
        isPending: true,
        status: CurrentThreadStatus.threadPending
      })
      createThread({ localId: localThreadId, messages: initialMessages })
    },
    [assistantId, addHistoryThread, setCurrentThread, createThread]
  )

  const sendMessage = useCallback(
    (messageContent: string) => {
      if (isPending || !assistantId) {
        return
      }
      const currentThreadId = currentThread?.threadId

      if (!currentThreadId) {
        createThreadAndPostMessage({ messageContent })
        return
      }

      createInitialMessages({
        threadId: currentThreadId,
        content: messageContent
      }).forEach(m => {
        appendMessage({ threadId: currentThreadId, message: m })
      })
      postMessage({
        threadId: currentThreadId,
        messageContent
      })
    },
    [
      appendMessage,
      assistantId,
      createThreadAndPostMessage,
      currentThread?.threadId,
      isPending,
      postMessage
    ]
  )

  const retrySendMessage = useCallback(() => {
    const lastUserMessageIdx =
      currentThread?.messages?.findLastIndex(m => m.role === 'user') ?? -1
    const lastUserMessage =
      lastUserMessageIdx >= 0
        ? currentThread?.messages?.[lastUserMessageIdx]
        : undefined

    const lastUserMessageHasResponseContent =
      lastUserMessageIdx >= 0 &&
      !!currentThread?.messages
        .slice(lastUserMessageIdx + 1)
        .find(m => m.role === 'assistant')?.content

    const currentThreadId = currentThread?.threadId

    if (!currentThreadId) {
      if (!lastUserMessage?.content) {
        console.error('No message content to retry')
        return
      }
      if (!currentThread?.localThreadId) {
        console.error('No local thread id to retry')
        return
      }
      if (currentThread.localThreadId) {
        // TOOD: Handle case of creating new thread on server for existing local
        // thread
        return
      }
      // Try creating a new thread with the last user message content
      // TODO: test this
      createThreadAndPostMessage({ messageContent: lastUserMessage?.content })
      return
    }

    // Thread already exists, so we retry the run on the existing thread
    if (lastUserMessageHasResponseContent) {
      // If last user message has a response, we "retry" the run by sending
      // the last user message again on the existing thread, while preserving
      // the previous assistant response

      const initialMessages = createInitialMessages({
        threadId: currentThreadId,
        content: lastUserMessage?.content || ''
      })
      appendMessage({ threadId: currentThreadId, message: initialMessages[0] })

      postMessage({
        threadId: currentThreadId,
        messageContent: lastUserMessage?.content
      })
    } else if (lastUserMessage?.id) {
      // if last user message has an id, it means it exists on the server
      // already, so we can just retry the run on the existing thread without
      // updating message content
      const [_, initialAssistantMsg] = createInitialMessages({
        threadId: currentThreadId,
        content: lastUserMessage?.content
      })
      appendMessage({ threadId: currentThreadId, message: initialAssistantMsg })
      postMessage({
        threadId: currentThreadId
      })
    } else if (lastUserMessage?.content) {
      // if last user message does not have an id, it means it does not exist
      // on the server yet, so we need to retry the run with the message
      // content
      const [_, initialAssistantMsg] = createInitialMessages({
        threadId: currentThreadId,
        content: lastUserMessage?.content
      })
      appendMessage({ threadId: currentThreadId, message: initialAssistantMsg })
      postMessage({
        threadId: currentThreadId,
        messageContent: lastUserMessage?.content
      })
    } else {
      console.error('No message content to retry')
    }
  }, [
    createThreadAndPostMessage,
    currentThread?.messages,
    currentThread?.threadId,
    postMessage,
    appendMessage,
    currentThread?.localThreadId
  ])

  return useMemo(
    () => ({
      sendMessage,
      retrySendMessage
    }),
    [retrySendMessage, sendMessage]
  )
}

export { useStreamMessage }

import posthog from 'posthog-js'
import ENV_VARS from '@/lib/envVars'
type EventProperties = {
  [key: string]: string | number | boolean | null | undefined
}

const isEnabled = ENV_VARS.NEXT_PUBLIC_VERCEL_ENV === 'production'
let isInitialized = false

export const analytics = {
  init: () => {
    if (!isEnabled || isInitialized) {
      return
    }

    const posthogKey = ENV_VARS.NEXT_PUBLIC_POSTHOG_KEY
    const posthogHost = ENV_VARS.NEXT_PUBLIC_POSTHOG_HOST

    if (!posthogKey || !posthogHost) {
      console.warn('PostHog configuration is missing')
      return
    }

    posthog.init(posthogKey, {
      api_host: posthogHost,
      capture_pageview: false, // We handle this separately in PostHogPageView
      capture_pageleave: true
    })

    isInitialized = true
  },

  track: (eventName: string, properties?: EventProperties) => {
    if (isEnabled) {
      posthog.capture(eventName, properties)
    }
  },

  identify: (userId: string | undefined, properties?: EventProperties) => {
    if (isEnabled) {
      posthog.identify(userId, properties)
    }
  },

  reset: () => {
    if (isEnabled) {
      posthog.reset()
    }
  },

  getAnalyticsInstance: () => {
    return isEnabled ? posthog : null
  }
}
